












import { Component, Inject, Vue } from 'vue-property-decorator'
import DataTable from '@/components/data-table/DataTable.vue'
import Calculator from '@/shared/modules/calculator/calculator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import http from '@/shared/http'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import DataTableAction from '@/shared/classes/data-table/data-table-action'
import { Routes } from '@/shared/router'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import CalculatorImportAsNewVersionDialog from '@/components/calculators/CalculatorImportAsNewVersionDialog.vue'
import CalculatorImportButton from '@/components/calculators/CalculatorImportButton.vue'
import { DataTableHeaderType } from '@/shared/configs/data-table.config'
import can from '@/shared/helpers/can'
import { Permission } from '@/shared/configs/permission.config'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { DataTable }
})
export default class VersionHistory extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator
  @Inject('refreshCalculator') refreshCalculator!: () => void

  table: DataTableBase = new DataTableBase()
    .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().reference }/versions`)
    .setHeaders([
      new DataTableHeader()
        .setKey('version')
        .setTitle('Version')
        .setValueParser((value: number) => `#${ value }`),
      new DataTableHeader()
        .setType(DataTableHeaderType.custom)
        .setKey('import_header')
        .setTitle('Status')
        .setMeta(() => ({
          component: CalculatorImportButton
        })),
      new DataTableHeader()
        .setKey('created_at')
        .setTitle('Date'),
      new DataTableHeader()
        .setTitle('Currently opened')
        .setKey('opened')
        .setClasses('status-badge')
        .setValueParser((value: any, item: Calculator) => {
          if (item.uuid === this.getCalculator().uuid) return 'Yes'
        })
        .setStyleParser((value: string, item: Calculator) => {
          const opened = item.uuid === this.getCalculator().uuid

          if (! opened) return {}

          return {
            container: {
              background: 'rgba(54, 179, 83, 0.4)',
              color: 'rgba(54, 179, 83, 1)',
              borderRadius: '4px',
              padding: '4px 15px',
            }
          }
        })
    ])
  .setActions([
    new DataTableAction()
      .setIcon(require('@/assets/images/eye.svg'))
      .setTitle('View')
      .setAction(async (item: Calculator) => {
        await this.$router.push({ name: Routes.calculatorsShow, params: { uuid: item.uuid } })
        window.location.reload()
      }),
    new DataTableAction()
      .setIcon('mdi-download')
      .setTitle('View')
      .setAction((item: Calculator) => {
        http.get(`clients/${ this.selectedClient.uuid }/calculators/${ item.uuid }/export`, { responseType: 'blob' })
          .then((response: any) => downloadFile(response, `calculator-export`))
      })
  ])

  copyToNewVersion() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 400,
      component: AreYouSureDialog,
      meta: {
        onYes: () => http.post(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/new-version`)
          .then(() => {
            this.tableRef.refresh()
            this.refreshCalculator()

            this.$store.dispatch(GlobalActions.showSnackbar, {
              type: SnackBarType.success,
              message: 'New version was successfully created'
            })
          })
      }
    })
  }

  importAsNewVersion() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 760,
      component: CalculatorImportAsNewVersionDialog,
      meta: {
        client: this.selectedClient,
        calculator: this.getCalculator(),
        onSuccess: () => {
          this.tableRef.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Imported successfully'
          })
        }
      }
    })
  }

  isNewVersionDisabled() {
    return ! can([Permission.calculators.newVersion], false, this.getCalculator())
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
