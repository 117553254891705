






import { Component, Prop, Vue } from 'vue-property-decorator'
import Calculator from '@/shared/modules/calculator/calculator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Client from '@/shared/modules/client/client'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import CalculatorImportDialog from '@/components/calculators/CalculatorImportDialog.vue'
import { CalculatorStatus } from '@/shared/configs/calculator-statuses'
import capitalize from '@/shared/helpers/capitalize'

@Component({
  methods: { capitalize }
})
export default class CalculatorImportButton extends Vue {
  @Prop() item!: Calculator
  calculator: Calculator | null = null
  CalculatorStatus = CalculatorStatus

  openImportDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 760,
      component: CalculatorImportDialog,
      meta: {
        client: this.selectedClient,
        calculator: this.item,
      }
    })
  }

  get styles() {
    let background = ''
    let color = ''

    switch (this.item.status) {
      case CalculatorStatus.published:
        background = 'rgba(54, 179, 83, 0.4)'
        color = 'rgba(54, 179, 83, 1)'
        break
      case CalculatorStatus.draft:
        background = 'rgba(150, 150, 187, 0.4)'
        color = 'rgba(150, 150, 187, 1)'
        break
      case CalculatorStatus.cancelled:
        background = 'rgba(255, 128, 139, 0.4)'
        color = 'rgba(255, 128, 139, 1)'
        break
    }

    return {
      background,
      color,
      borderRadius: '4px',
      padding: '4px 15px',
    }
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
