import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum CalculatorStatus {
  published = 'published',
  draft = 'draft',
  cancelled = 'cancelled',
}

export const calculatorStatuses: SelectItem[] = [
  new SelectItem().setValue(CalculatorStatus.published).setTitle('Published'),
  new SelectItem().setValue(CalculatorStatus.draft).setTitle('Draft'),
  new SelectItem().setValue(CalculatorStatus.cancelled).setTitle('Cancelled'),
]
