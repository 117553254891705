



































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component({
  components: { PageHeader, Form }
})
export default class CalculatorImportAsNewVersionDialog extends Vue {
  @Prop() meta!: any
  showWarning: boolean = false
  loading: boolean = false

  form: FormBase = new FormBase()
    .setFiles(true)
    .setEndpoint(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/import-as-new-version`)
    .setFields([
      new Field()
        .setType(FieldType.file)
        .setKey('file')
        .setTitle('Upload CSV/XLSX'),
    ])
    .setSubmit(false)

  async submit() {
    this.showWarning = false
    this.loading = true

    const errors = await this.formRef.submit()
    this.loading = false
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
